@import "../../assets/scss/variables";
@import "../../assets/scss/mixins";

.favorites-page {
  margin: 0 auto;
  // position: relative;
  width: 100%;
  display: flex;
  flex-direction: column;
  align-items: center;
  .favorites-section {
    border-right: 1px solid rgb(219, 219, 219);
    background-color: white;
    border-left: 1px solid rgb(219, 219, 219);
    width: 397px;
    overflow-y: auto;

    @include lm {
      width: 100%;
    }

    .header-section {
      padding: 16px 24px;
      display: flex;
      justify-content: space-between;
      align-items: center;

      > h1 {
        font-family: -apple-system, BlinkMacSystemFont, "Segoe UI", Roboto, Helvetica, Arial, sans-serif;
        font-size: 24px;
      }
    }

    .middle-section {
      display: flex;
      justify-content: space-between;
      flex-direction: column;
      align-items: center;
      padding: 0px 40px;

      .favorites-icon {
        align-items: center;
        text-align: center;
        display: flex;
        padding: 14px;
        border: 1px solid black;
        border-radius: 100%;
        scale: 1.2;
      }

      > span {
        margin: 9px 0px;
        padding: 0px 6px;
        font-size: 14px;
        font-family: -apple-system, BlinkMacSystemFont, "Segoe UI", Roboto, Helvetica, Arial, sans-serif;
        text-align: center;
      }
    }

    .favorites {
      padding: 0px 10px;
      display: flex;
      flex-direction: column;
      align-items: center;

      .board_favorites {
        display: flex;
        align-items: center;
        width: 100%;
        padding: 5px 5px;
        gap: 10px;
        cursor: pointer;

        .avatar_section {
          > div {
            > img {
              width: 44px;
              height: 44px;
              border-radius: 50%;
              object-fit: contain;
            }
          }
        }

        .info_section {
          display: flex;

          flex-direction: column;
          width: 100%;
          padding-bottom: 5px;

          > p {
            white-space: wrap;
            font-weight: 600;
            font-size: 14px;
            font-family: -apple-system, BlinkMacSystemFont, "Segoe UI", Roboto, Helvetica, Arial, sans-serif;
            margin-bottom: 6px;
          }

          > span {
            font-size: 12px;
            font-family: -apple-system, BlinkMacSystemFont, "Segoe UI", Roboto, Helvetica, Arial, sans-serif;
          }
        }
      }

      > span {
        color: rgb(0, 149, 246);
        font-size: 14px;
        font-weight: 600;
        font-family: -apple-system, BlinkMacSystemFont, "Segoe UI", Roboto, Helvetica, Arial, sans-serif;
        cursor: pointer;

        &:hover {
          color: rgb(0, 55, 107);
        }
      }
    }
  }
}
