@import "../../assets/scss/variables";
@import "../../assets/scss/mixins";

.login-page {
  height: 100vh;
  display: flex;
  justify-content: center;
  align-items: center;
  &-section {
    display: grid;
    justify-items: center;
    grid-gap: 16px;
    &-form {
      display: grid;
      justify-items: center;
      padding: 40px;
      border: 1px solid rgb(219, 219, 219);
      grid-gap: 24px;
      &-title {
        font-size: 42px;
        margin-bottom: 8px;
        margin-top: 24px;
        > img {
          width: 80px;
        }
      }
      .form_login {
        display: grid;
        grid-gap: 16px;
        .label {
          .input {
            min-height: 38px;
            min-width: 268px;
            padding: 8px;
            border: 1px solid rgb(219, 219, 219);
            outline: none;
            &:focus {
              border-color: #000;
            }
          }
        }
        .remember_select {
          display: flex;
          justify-content: center;
          align-items: center;
          font-size: 16px;
          color: rgb(0, 55, 107);
          > input {
            margin-left: 8px;
          }
        }
        .login_button {
          height: 32px;
          border-radius: 8px;
          background: rgb(0, 149, 246);
          border: none;
          color: white;
          font-size: 14px;
          font-weight: 500;
          display: inline-flex;
          align-items: center;
          justify-content: center;
          position: relative;
        }
      }
      .forget-pass-nav {
        > a {
          font-size: 12px;
          text-decoration: none;
          color: rgb(0, 55, 107);
        }
      }

      .message-error {
        color: red;
        // background-color: rgb(255, 189, 189);
        // border-radius: 10px;
        // padding: 5px 20px;
        // border: 1px solid red;
      }
    }
    &-register {
      padding: 16px 40px;
      border: 1px solid rgb(219, 219, 219);
      width: 100%;
      display: flex;
      justify-content: center;
      align-items: center;
      > div {
        > a {
          color: rgb(0, 149, 246);
          text-decoration: none;
          font-weight: 600;
        }
      }
    }
  }
}

.loader {
  font-size: 10px;
  border-top: 4px solid rgba(255, 255, 255, 0.2);
  border-right: 4px solid rgba(255, 255, 255, 0.2);
  border-bottom: 4px solid rgba(255, 255, 255, 0.2);
  border-left: 4px solid #ffffff;
  transform: translateZ(0);
  animation: loaderSpin 1.1s infinite linear;
  margin-right: 10px;
}

.loader,
.loader:after {
  border-radius: 50%;
  width: 20px;
  height: 20px;
}

@keyframes loaderSpin {
  0% {
    transform: rotate(0deg);
  }
  100% {
    transform: rotate(360deg);
  }
}
