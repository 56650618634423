@import "../../assets/scss/variables";
@import "../../assets/scss/mixins";

.verify-page {
  height: 100vh;
  display: flex;
  justify-content: center;
  align-items: center;
  &-section {
    display: grid;
    justify-items: center;
    max-width: 388px;
    &-form {
      display: grid;
      justify-items: center;
      padding: 40px;
      border: 1px solid rgb(219, 219, 219);
      grid-gap: 24px;
      &-title {
        font-size: 42px;
        margin-bottom: 8px;
        margin-top: 24px;
        text-align: center;
        > img {
          width: 80px;
        }
        > h3 {
          font-size: 20px;
        }
      }

      &-description {
        display: grid;
        grid-template-columns: 1fr;
        grid-gap: 16px;
        > p {
          font-size: 16px;
          text-align: center;
        }
      }
      &-action {
        display: grid;
        grid-template-columns: 1fr;
        justify-items: center;
        width: 100%;
        grid-gap: 16px;
        .verify-btn {
          height: 32px;
          border-radius: 8px;
          background: rgb(0, 149, 246);
          border: none;
          color: white;
          font-size: 14px;
          font-weight: 500;
          width: 100%;
        }
      }
    }
    &-back {
      width: 100%;
      > button {
        width: 100%;
        padding: 16px 40px;
        border: 1px solid rgb(219, 219, 219);
        border-top: none;
        display: flex;
        justify-content: center;
        align-items: center;
        cursor: pointer;
        background: #fff;
        transition: 0.3s;
        &:hover {
          background: rgb(240, 240, 240);
        }
      }
    }
  }
}
