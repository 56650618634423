@import "../../assets/scss/variables";
@import "../../assets/scss/mixins";

.sidebar {
  // position: relative;
  width: 244px;
  padding: 40px 16px;
  border-right: 1px solid rgb(219, 219, 219);
  // display: grid;
  background: #fff;
  justify-items: center;
  align-items: center;
  // z-index: 0;
  // grid-template-rows: auto 1fr auto;
  grid-gap: 40px;
  height: 100vh;

  @include md {
    height: 5vh;
    width: 100vw;
  }

  .website-logo {
    width: 100%;
    display: flex;
    align-items: center;
    padding-left: 5px;

    > img {
      width: 40px;
      cursor: pointer;

      @include sm {
        width: 30px;
      }
    }

    > h3 {
      margin-left: 16px;
      font-size: 20px;
      font-weight: bold;

      @include lm {
        display: none;
      }
    }

    &:hover {
      background: rgb(250, 250, 250);
      transition: all 0.3s;
      cursor: pointer;
      border-radius: 5px;
    }
  }

  .sidbar-nav {
    display: flex;
    flex-direction: column;
    // grid-gap: 16px;
    width: 100%;

    @include md {
      flex-direction: row;
      justify-content: space-around;
      grid-gap: 2px;
    }
    @include sm {
      padding: 0px 10px;
      grid-gap: 2px;
    }

    .hide-discovery {
      border: 1px red solid;
      display: none;
      @include lm {
      }
    }
    .nav-item {
      padding: 12px;
      border-radius: 14px;
      display: flex;
      align-items: center;

      > img {
        width: 24px;
        height: 24px;
        border-radius: 50%;
      }

      .nav-name,
      .user-name {
        margin-left: 16px;
        font-size: 16px;
        font-weight: 400;

        @include lm {
          display: none;
        }
      }

      @include lm {
        border-radius: 50%;
        display: flex;
      }

      &:hover {
        background: rgb(250, 250, 250);
        transition: all 0.3s;
        cursor: pointer;
      }

      &.active {
        .nav-name,
        .user-name {
          font-weight: bold;
        }

        > svg {
          path {
            fill: #000;
          }
        }
      }

      &.search-active {
        > svg {
          path {
            fill: white !important;
          }
        }
      }
    }
  }

  @include lm {
    width: 80px;
  }

  @include md {
    width: 100%;
    display: flex;
    position: fixed;
    bottom: 0;
    padding: 0;
    gap: 0;
  }

  .sidebar-footer {
    // position: relative;
    width: 100%;
    display: flex;
    justify-content: start;
    grid-gap: 16px;
    align-items: center;
    padding: 12px 0 12px 12px;
    cursor: pointer;
    // z-index: 9999;

    &:hover {
      background-color: #0000000d;
      border-radius: 7px;
    }

    > p {
      font-size: 16px;

      @include lg {
        display: none;
      }
    }

    .hamburger-menu {
      width: 266px;
      position: fixed;
      top: 50%;
      left: 50%;
      transform: translate(-50%, -50%);
      background-color: white;
      padding: 8px;
      border-radius: 16px;
      box-shadow: 0 0 15px 20px #0000000d;
      z-index: 99999;

      .hamburger-menu-row {
        display: flex;
        justify-content: space-between;
        align-items: center;
        padding: 16px;

        &:hover {
          background-color: #0000000d;
          border-radius: 12px;
        }

        > div {
          display: flex;
          align-items: center;

          > p {
            margin-left: 12px;
            font-size: 14px;
            font-weight: 400;
          }
        }

        .arrow-icon {
          transform: rotate(90deg);
        }
      }
    }

    @include xl {
      align-items: end;
    }

    // @include md {
    //   display: none;
    // }
  }
}
