@import "../../../assets/scss/variables";
@import "../../../assets/scss/mixins";

.modal {
    .modal-content {

        .modal-block {
            position: relative;
            width: 550px;

            .modal-header {
                padding: 16px;

                >h4 {
                    font-size: 16px;
                    font-weight: 700;
                    font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', Roboto, Helvetica, Arial, sans-serif;
                }
            }

            .modal-search {
                width: 100%;
                padding: 16px;
                border-bottom: 1px solid rgb(219, 219, 219);

                .modal_added {
                    display: flex;
                    gap: 3px;
                    flex-wrap: wrap;
                    cursor: pointer;
                    align-items: center;

                    .user_added {
                        display: inline-block;
                        background-color: rgb(224, 241, 255);
                        padding: 4px 8px;
                        border-radius: 15px;

                        >span {
                            color: #0095f6;
                            font-weight: 600;
                            font-size: 15px;
                            font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', Roboto, Helvetica, Arial, sans-serif;
                        }

                        >button {
                            color: #0095f6;
                            outline: none;
                            background: none;
                            border: none;
                            font-size: 18px;
                            cursor: pointer;
                            height: 12px;
                            width: 12px;
                        }
                    }
                }



                >label {
                    font-size: 16px;
                    font-weight: 600;
                    font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', Roboto, Helvetica, Arial, sans-serif;
                }

                >input {
                    margin-left: 20px;
                    border: none;
                    outline: none;

                }
            }

            .users-section {
                padding: 24px 0px;
                height: 45vh;
                overflow-y: auto;

                .not-found {
                    padding: 12px 24px;

                    >span {
                        color: #737373;
                        font-size: 14px;
                        font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', Roboto, Helvetica, Arial, sans-serif;

                    }
                }

                .board_users {
                    display: flex;
                    align-items: center;
                    width: 100%;
                    padding: 5px 5px;
                    gap: 10px;
                    cursor: pointer;

                    .avatar_section {
                        >div {
                            >img {
                                width: 44px;
                                height: 44px;
                                border-radius: 50%;
                            }
                        }
                    }

                    .info_section {
                        display: flex;
                        justify-content: space-between;
                        width: 100%;
                        padding-bottom: 5px;

                        >p {
                            white-space: nowrap;
                            font-weight: 600;
                            font-size: 14px;
                            font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', Roboto, Helvetica, Arial, sans-serif;

                        }
                    }

                    .chackbox-div {
                        padding-left: 12px;
                        padding-right: 12px;
                        display: flex;
                        align-items: center;
                        text-align: center;

                        .container {
                            display: block;
                            position: relative;
                            padding-left: 35px;
                            margin-bottom: 25px;
                            cursor: pointer;
                            font-size: 14px;
                            -webkit-user-select: none;
                            -moz-user-select: none;
                            -ms-user-select: none;
                            user-select: none;
                        }

                        .container input {
                            position: absolute;
                            opacity: 0;
                            cursor: pointer;
                            height: 0;
                            width: 0;
                        }

                        .checkmark {
                            position: absolute;
                            top: 0;
                            left: 0;
                            height: 22px;
                            width: 22px;
                            background-color: #eee;
                            border-radius: 50%;
                        }

                        .container:hover input~.checkmark {
                            background-color: #ccc;
                        }

                        .container input:checked~.checkmark {
                            background-color: #2196F3;
                        }

                        .checkmark:after {
                            content: "";
                            position: absolute;
                            display: none;
                        }

                        .container input:checked~.checkmark:after {
                            display: block;
                        }

                        .container .checkmark:after {
                            top: 7px;
                            left: 7px;
                            width: 8px;
                            height: 8px;
                            border-radius: 50%;
                            background: white;
                        }
                    }

                    &:hover {
                        background-color: rgba(219, 219, 219, 0.5);
                    }
                }
            }

            .button-section {
                margin-top: 10px;
                padding: 16px;

                >button {
                    width: 100%;
                    height: 100%;
                    padding: 13px 0;
                    border-radius: 8px;
                    border: none;
                    outline: none;
                    font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', Roboto, Helvetica, Arial, sans-serif;
                    color: white;
                    font-size: 14px;
                    font-weight: 600px;
                    background-color: #0095F6;
                    cursor: pointer;

                    &:disabled {
                        opacity: 0.6;
                    }
                }
            }

            >div {
                >svg {
                    position: absolute;
                    top: 16px;
                    right: 16px;
                    cursor: pointer;
                    color: black;
                }
            }
        }
    }
}