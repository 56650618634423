@import "../../assets/scss/variables";
@import "../../assets/scss/mixins";

.emojis {
    .EmojiPickerReact {
        height: 350px !important;
        width: 350px !important;

        @include lg {
            height: 250px !important;
            width: 250px !important;
        }

        @include md {
            height: 230px !important;
            width: 230px !important;
        }

        @include sm {
            height: 180px !important;
            width: 180px !important;
        }

        .epr-header {
            .epr-category-nav {
                @include lg {
                    display: none;
                }
            }

            .epr-header-overlay {
                @include sm {
                    display: none;
                }
            }

        }

        .epr-preview {
            @include lg {
                height: 40px !important;
            }

            >div {
                img {
                    @include lg {

                        height: 30px !important;
                        width: 30px !important;
                    }
                }
            }
        }
    }
}