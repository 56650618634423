@import "../../../assets/scss/variables";
@import "../../../assets/scss/mixins";


.aaaa {
    position: absolute;
    background-color: white;
    width: 350px;
    max-height: 300px;
    border-radius: 15px;
    box-shadow: 0px 0px 15px black;

    .avatar_info {
        display: flex;
        justify-content: flex-start;
        padding: 10px;
        gap: 10px;
        align-items: center;

        >div {
            cursor: pointer;

            >img {
                width: 50px;
                height: 50px;
                border-radius: 50%;
            }

            >p {
                cursor: pointer;
                font-weight: 600;
                font-size: 14px;
                font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', Roboto, Helvetica, Arial, sans-serif;
            }
        }
    }

    .account_info {
        display: flex;
        justify-content: space-around;

        >div {
            justify-content: space-around;
            gap: 10px;
            display: flex;
            padding: 10px;
            text-align: center;

            >div {
                >span {
                    font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', Roboto, Helvetica, Arial, sans-serif;
                    font-size: 16px;
                }

            }
        }
    }

    .publications_info {
        display: flex;
        justify-content: space-around;

        >img {
            cursor: pointer;
            width: 110px;
            height: 110px;
        }
    }

    .button_subscribe {
        position: relative;
        padding: 10px;

        .btn-subscribe {
            display: flex;
            justify-content: center;
            gap: 3px;
            width: 100%;
            cursor: pointer;
            background-color: rgb(0, 149, 246);
            padding: 8px 14px;
            font-size: 14px;
            color: white;
            border-radius: 5px;
            outline: none;
            border: none;
            font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', Roboto, Helvetica, Arial, sans-serif;
        }
    }
}