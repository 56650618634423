@import "../../assets/scss/variables";
@import "../../assets/scss/mixins";

.addproduct-page {
  margin: 0 auto;
  padding-bottom: 60px;
  height: 100%;

  .product-container {
    max-width: 570px;
    margin-top: 30px;
    display: flex;
    flex-direction: column;
    gap: 15px;
    height: 80%;

    > div {
      > p {
        font-size: 14px;
        color: black;
        font-family: -apple-system, BlinkMacSystemFont, "Segoe UI", Roboto,
          Helvetica, Arial, sans-serif;
        font-weight: 500;
        margin-bottom: 4px;
      }

      > input {
        padding: 8px 10px;
        width: 100%;
        outline: none;
        border-top: none;
        border-right: none;
        border-left: none;
        border-bottom: 1px solid rgb(211, 211, 211);

        &:focus {
          border-bottom: 1px solid #0095f6;
        }
      }

      > textarea {
        font: 14px;
        width: 100%;
        outline: none;
        border: 1px solid rgb(211, 211, 211);
        border-radius: 3px;
        min-height: 100px;
        resize: none;
        padding: 10px;

        &:focus {
          border: 1px solid #0095f6;
        }

        &::-webkit-scrollbar {
          width: 5px;
        }

        &::-webkit-scrollbar-track {
          border-radius: 10px;
        }

        &::-webkit-scrollbar-thumb {
          background: rgb(219, 219, 219);
          border-radius: 10px;
        }
      }
    }

    .button_section {
      width: 100%;
      display: flex;
      justify-content: end;

      > button {
        width: 50%;
        background-color: #0095f6;
        color: white;
        border: none;
        border-radius: 5px;
        padding: 5px 0;
        cursor: pointer;

        &:hover {
          opacity: 0.7;
          transition: 0.5s;
        }
      }
    }

    .upload-file {
      font-size: 14px;
      color: #0095f6;
      font-family: -apple-system, BlinkMacSystemFont, "Segoe UI", Roboto,
        Helvetica, Arial, sans-serif;
      font-weight: 600;
      cursor: pointer;

      &:hover {
        color: rgb(0, 55, 107);
      }
    }

    .save-info {
      font-size: 12px;
      color: rgb(121, 119, 119);
      font-family: -apple-system, BlinkMacSystemFont, "Segoe UI", Roboto,
        Helvetica, Arial, sans-serif;
      margin: 10px 0px 0px 0px;
    }

    .avatar {
      display: flex;
      justify-content: end;
      border: 2px solid black;
      height: 300px;
      overflow: hidden;

      > img {
        height: 100%;
        width: 100%;
        object-fit: cover;
      }
    }
  }
  @include sm {
    padding-right: 10px;
    padding-left: 10px;
  }
}
