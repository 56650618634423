@import "../../../assets/scss/variables";
@import "../../../assets/scss/mixins";

.profile-settings {
  .modal-content {
    .settings-modal {
      background-color: white;
      border-radius: 12px;
      position: fixed;
      top: 50%;
      left: 50%;
      transform: translate(-50%, -50%);

      > div {
        text-align: center;
        padding: 16px 135px;
        cursor: pointer;

        &:not(:last-child) {
          border-bottom: 1px solid #dbdbdb;
        }

        > p {
          font-size: 14px;
        }
      }

      .general-row {
        color: #ed4956;
        font-weight: 700;
      }
    }
  }
}
