@import "../../assets/scss/variables";
@import "../../assets/scss/mixins";

.general-page-settings {
  max-width: 933px;
  width: 100%;
  margin: 0 auto;

  > h1 {
    margin-left: 24px;
    margin-top: 24px;
  }

  .settings-page {
    margin-top: 50px;
    @include md {
      margin-top: 16px;
    }

    .settings-page-container {
      border: 1px solid rgb(211, 211, 211);
      display: flex;
      //   grid-template-columns: minmax(auto, 232px) 1fr;
      margin: 0 10px;

      .right-section {
        border-left: 1px solid rgb(211, 211, 211);
        width: 100%;

        > div {
          > h1 {
            margin-top: 32px;
            margin-left: 45px;
            font-size: 24px;
            font-weight: 400;
            line-height: 27px;
          }

          .edit-profile {
            margin-top: 60px;
            margin-bottom: 30px;

            .edit-row-1 {
              width: 100%;
              display: grid;
              grid-template-columns: 1fr 3fr;
              grid-gap: 32px;
              margin-top: 16px;

              .avatar {
                border-radius: 50%;
                display: flex;
                justify-content: end;

                > img {
                  border-radius: 50%;
                  height: 38px;
                  width: 38px;
                }
              }

              .left-part {
                display: flex;
                justify-content: end;

                > label {
                  font-size: 16px;
                  font-weight: 600;
                  font-family: -apple-system, BlinkMacSystemFont, "Segoe UI",
                    Roboto, Helvetica, Arial, sans-serif;
                }

                .checkbox-label {
                  text-align: end;
                  padding-left: 100px;
                }
              }

              .check-accounts {
                display: flex;
                align-items: center;
                grid-gap: 8px;

                > div {
                  max-width: 400px;

                  > label {
                    font-weight: 600;
                    font-weight: -apple-system, BlinkMacSystemFont, "Segoe UI",
                      Roboto, Helvetica, Arial, sans-serif;
                    font-size: 14px;

                    > span {
                      color: #0095f6;
                      cursor: pointer;

                      &:hover {
                        text-decoration: underline;
                      }
                    }
                  }
                }
              }

              > div {
                > h3 {
                  font-size: 16px;
                  font-weight: 400;
                  font-family: -apple-system, BlinkMacSystemFont, "Segoe UI",
                    Roboto, Helvetica, Arial, sans-serif;
                }

                .upload-file {
                  font-size: 14px;
                  color: #0095f6;
                  font-family: -apple-system, BlinkMacSystemFont, "Segoe UI",
                    Roboto, Helvetica, Arial, sans-serif;
                  font-weight: 600;
                  cursor: pointer;

                  &:hover {
                    color: rgb(0, 55, 107);
                  }
                }

                .save-info {
                  margin: 10px 0px 0px 0px;
                }

                > input {
                  padding: 8px 10px;
                  width: 80%;
                  outline: none;
                  border: 1px solid rgb(211, 211, 211);
                  border-radius: 3px;
                }

                > textarea {
                  width: 80%;
                  outline: none;
                  border: 1px solid rgb(211, 211, 211);
                  border-radius: 3px;
                  min-height: 100px;
                  resize: none;
                  padding: 10px;
                }

                > p {
                  font-size: 12px;
                  color: #737373;
                }

                > h6 {
                  margin-top: 16px;
                  font-weight: 400;
                  font-size: 12px;
                  color: #737373;
                  font-family: -apple-system, BlinkMacSystemFont, "Segoe UI",
                    Roboto, Helvetica, Arial, sans-serif;
                }

                > button {
                  margin-top: 16px;
                  padding: 7px 14px;
                  outline: none;
                  border: none;
                  border-radius: 8px;
                  color: white;
                  background-color: rgb(0, 149, 246);
                  cursor: pointer;

                  &:hover {
                    background-color: rgb(24, 119, 242);
                  }
                }
              }

              > div {
                > input {
                  padding: 8px 10px;
                  width: 80%;
                  outline: none;
                  border: 1px solid rgb(211, 211, 211);
                  border-radius: 3px;
                }

                > textarea {
                  width: 80%;
                  outline: none;
                  border: 1px solid rgb(211, 211, 211);
                  border-radius: 3px;
                  min-height: 100px;
                  resize: none;
                }

                > p {
                  font-size: 12px;
                  color: #737373;
                }

                > h6 {
                  margin-top: 16px;
                  font-weight: 400;
                  font-size: 12px;
                  color: #737373;
                  font-family: -apple-system, BlinkMacSystemFont, "Segoe UI",
                    Roboto, Helvetica, Arial, sans-serif;
                }

                > button {
                  margin-top: 16px;
                  padding: 7px 14px;
                  outline: none;
                  border: none;
                  border-radius: 8px;
                  color: white;
                  background-color: rgb(0, 149, 246);
                  cursor: pointer;

                  &:hover {
                    background-color: rgb(24, 119, 242);
                  }
                }
              }
            }
          }
        }

        .website-content {
          > h1 {
            margin-left: 65px !important;
            font-size: 16px !important;
            margin-top: 68px !important;
          }

          .tabs-row {
            padding: 32px 44px 35px 65px;

            > div {
              display: grid;
              grid-template-columns: 1fr 1fr 1fr;
              border-bottom: 1px solid rgb(211, 211, 211);
              cursor: pointer;

              > div {
                display: flex;
                justify-content: center;
                align-items: center;
                padding: 14px 0;

                > p {
                  font-size: 14px;
                  color: #b2c3d2;
                  font-weight: 600;
                  font-family: -apple-system, BlinkMacSystemFont, "Segoe UI",
                    Roboto, Helvetica, Arial, sans-serif;
                }
              }
            }
          }

          .content-section {
            padding: 0 44px 35px 65px;

            > div {
              > p {
                font-size: 14px;
                font-weight: 400;
                font-family: -apple-system, BlinkMacSystemFont, "Segoe UI",
                  Roboto, Helvetica, Arial, sans-serif;
                margin-bottom: 24px;
              }

              > span {
                font-size: 12px;
                color: #737373;
                font-weight: 400;
                font-family: -apple-system, BlinkMacSystemFont, "Segoe UI",
                  Roboto, Helvetica, Arial, sans-serif;
              }
            }
          }
        }

        .email-notifications-section {
          .select-section {
            padding-top: 48px;
            padding-left: 64px;
            padding-right: 32px;
            padding-bottom: 32px;

            .select-areas {
              padding-bottom: 5px;
              padding-top: 16px;

              &:not(:last-child) {
                border-bottom: 1px solid rgb(211, 211, 211);
              }

              > h4 {
                color: rgb(38, 38, 38);
                font-weight: 700;
                display: inline;
                font-size: 16px;
                line-height: 1.3;
              }

              > p {
                margin-top: 25px;
                color: #8e8e8e;
                font-size: 12px;
                font-family: system-ui, -apple-system, BlinkMacSystemFont,
                  "Segoe UI", Roboto, Helvetica, Arial, sans-serif;
              }

              > div {
                padding-top: 16px;
                padding-left: 12px;
                padding-right: 12px;

                .container {
                  display: block;
                  position: relative;
                  padding-left: 35px;
                  margin-bottom: 25px;
                  cursor: pointer;
                  font-size: 14px;
                  -webkit-user-select: none;
                  -moz-user-select: none;
                  -ms-user-select: none;
                  user-select: none;
                }

                .container input {
                  position: absolute;
                  opacity: 0;
                  cursor: pointer;
                  height: 0;
                  width: 0;
                }

                .checkmark {
                  position: absolute;
                  top: 0;
                  left: 0;
                  height: 22px;
                  width: 22px;
                  background-color: #eee;
                  border-radius: 50%;
                }

                .container:hover input ~ .checkmark {
                  background-color: #ccc;
                }

                .container input:checked ~ .checkmark {
                  background-color: #2196f3;
                }

                .checkmark:after {
                  content: "";
                  position: absolute;
                  display: none;
                }

                .container input:checked ~ .checkmark:after {
                  display: block;
                }

                .container .checkmark:after {
                  top: 7px;
                  left: 7px;
                  width: 8px;
                  height: 8px;
                  border-radius: 50%;
                  background: white;
                }
              }
            }
          }
        }

        .push-notification-section {
          .push-notification-container {
            padding-top: 48px;
            padding-left: 45px;
            padding-right: 44px;
            padding-bottom: 32px;

            .select-areas {
              padding-top: 28px !important;
            }

            .notification-sections {
              > h4 {
                color: #262626;
              }

              .toggle-row {
                display: flex;
                align-items: center;
                justify-content: space-between;
                margin-top: 28px;

                > p {
                  font-size: 16px;
                  color: #262626;
                  line-height: 1.3;
                  font-family: system-ui, -apple-system, BlinkMacSystemFont,
                    "Segoe UI", Roboto, Helvetica, Arial, sans-serif;
                }

                .toggle {
                  .switch {
                    position: relative;
                    display: inline-block;
                    width: 44px;
                    height: 28px;
                  }

                  .switch input {
                    opacity: 0;
                    width: 0;
                    height: 0;
                  }

                  .slider {
                    position: absolute;
                    cursor: pointer;
                    top: 0;
                    left: 0;
                    right: 0;
                    bottom: 0;
                    background-color: #ccc;
                    -webkit-transition: 0.4s;
                    transition: 0.4s;
                  }

                  .slider:before {
                    position: absolute;
                    content: "";
                    height: 22px;
                    width: 22px;
                    left: 3px;
                    bottom: 3px;
                    background-color: white;
                    -webkit-transition: 0.4s;
                    transition: 0.4s;
                  }

                  input:checked + .slider {
                    background-color: #2196f3;
                  }

                  input:focus + .slider {
                    box-shadow: 0 0 1px #2196f3;
                  }

                  input:checked + .slider:before {
                    -webkit-transform: translateX(16px);
                    -ms-transform: translateX(16px);
                    transform: translateX(16px);
                  }

                  /* Rounded sliders */
                  .slider.round {
                    border-radius: 34px;
                  }

                  .slider.round:before {
                    border-radius: 50%;
                  }
                }
              }
            }
          }
        }

        .what-do-you-see-content {
          .what-do-you-see-content-container {
            .likes-and-views {
              border-bottom: 1px solid rgb(211, 211, 211);
              padding-bottom: 22px;

              > p {
                font-size: 12px;
                color: #8e8e8e;
                line-height: 1.3;
                font-family: system-ui, -apple-system, BlinkMacSystemFont,
                  "Segoe UI", Roboto, Helvetica, Arial, sans-serif;
                margin-top: 16px;
              }
            }

            .help-support {
              padding-top: 22px;

              > h4 {
                font-weight: 600;
                font-family: -apple-system, BlinkMacSystemFont, "Segoe UI",
                  Roboto, Helvetica, Arial, sans-serif;
              }

              > p {
                margin-top: 16px;
                font-family: -apple-system, BlinkMacSystemFont, "Segoe UI",
                  Roboto, Helvetica, Arial, sans-serif;
                color: #0095f6;
                font-weight: 500;
                cursor: pointer;
                font-size: 14px;
              }
            }
          }
        }

        .see-your-content {
          > h1 {
            font-family: -apple-system, BlinkMacSystemFont, "Segoe UI", Roboto,
              Helvetica, Arial, sans-serif;
          }

          .see-your-content-container {
            padding: 24px 48px;

            .account-privacy {
              border-bottom: 1px solid rgb(211, 211, 211);
              padding-bottom: 22px;

              > h4 {
                font-size: 16px;
                font-weight: 600;
                font-family: -apple-system, BlinkMacSystemFont, "Segoe UI",
                  Roboto, Helvetica, Arial, sans-serif;
              }

              .closed-account {
                display: flex;
                grid-gap: 8px;
                align-items: center;

                > div {
                  margin-top: 16px;

                  > input {
                    border: 1px solid rgb(211, 211, 211);
                  }

                  > label {
                    font-size: 14px;
                    font-family: -apple-system, BlinkMacSystemFont, "Segoe UI",
                      Roboto, Helvetica, Arial, sans-serif;
                    font-weight: 600;
                  }
                }
              }

              > p {
                margin-top: 10px;
                color: #737373;
                line-height: 20px;
                font-size: 14px;
                font-weight: 400;
                font-family: -apple-system, BlinkMacSystemFont, "Segoe UI",
                  Roboto, Helvetica, Arial, sans-serif;
              }

              > h5 {
                font-size: 14px;
                color: #0095f6;
                cursor: pointer;
                font-weight: 500;
                font-family: -apple-system, BlinkMacSystemFont, "Segoe UI",
                  Roboto, Helvetica, Arial, sans-serif;

                &:hover {
                  text-decoration: underline;
                }
              }
            }

            .blocked-accounts {
              padding: 22px 0;
              border-bottom: 1px solid rgb(211, 211, 211);

              > h4 {
                font-size: 16px;
                font-weight: 600;
                font-family: -apple-system, BlinkMacSystemFont, "Segoe UI",
                  Roboto, Helvetica, Arial, sans-serif;
              }

              > h5 {
                font-size: 14px;
                color: #0095f6;
                cursor: pointer;
                font-weight: 500;
                font-family: -apple-system, BlinkMacSystemFont, "Segoe UI",
                  Roboto, Helvetica, Arial, sans-serif;
                margin-top: 16px;
              }
            }

            .help-support {
              padding-top: 22px;

              > h4 {
                font-size: 16px;
                font-weight: 600;
                font-family: -apple-system, BlinkMacSystemFont, "Segoe UI",
                  Roboto, Helvetica, Arial, sans-serif;
              }

              > h5 {
                font-size: 14px;
                color: #0095f6;
                cursor: pointer;
                font-weight: 500;
                font-family: -apple-system, BlinkMacSystemFont, "Segoe UI",
                  Roboto, Helvetica, Arial, sans-serif;
                margin-top: 16px;
              }
            }
          }
        }

        .help {
          > h1 {
            margin-left: 64px !important;
            font-weight: 700;
            font-family: -apple-system, BlinkMacSystemFont, "Segoe UI", Roboto,
              Helvetica, Arial, sans-serif;
          }

          .help-container {
            padding: 32px 64px 0 64px;

            > div {
              cursor: pointer;
              display: flex;
              justify-content: space-between;
              align-items: center;

              &:not(:first-child) {
                margin-top: 32px;
              }

              .arrow-right {
                transform: rotate(90deg);
                height: 16px;
                width: 16px;
                color: #8e8e8e;
              }

              > p {
                font-size: 16px;
                font-family: system-ui, -apple-system, BlinkMacSystemFont,
                  "Segoe UI", Roboto, Helvetica, Arial, sans-serif;
                color: #262626;
              }
            }
          }
        }
      }
    }
  }
}
