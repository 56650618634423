@import "./assets/scss/variables";

* {
  font-family: "Noto Sans", sans-serif;
  margin: 0;
  padding: 0;
  box-sizing: border-box;
}
body {
  min-height: 100vh;
}

.container {
  max-width: 992px;
  margin: 0 auto;
}
