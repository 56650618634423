@import "_variables.scss";

@mixin xs {
  @media (max-width: #{$screen-xs-max}) {
    @content;
  }
}

// Small tablets and large smartphones (landscape view)
@mixin sm {
  @media (max-width: #{$screen-sm-max}) {
    @content;
  }
}

// Small tablets (portrait view)
@mixin md {
  @media (max-width: #{$screen-md-max}) {
    @content;
  }
}

// Tablets and small desktops
@mixin lg {
  @media (max-width: #{$screen-lg-max}) {
    @content;
  }
}

@mixin lm {
  @media (max-width: #{$screen-lm-max}) {
    @content;
  }
}

// Large tablets and desktops
@mixin xl {
  @media (max-width: #{$screen-xl-max}) {
    @content;
  }
}

.oblast-app {
  display: flex;
  // grid-template-columns: 244px 1fr;
  // min-height: 100dvh;
  flex-direction: row;

  @include lm {
    // grid-template-columns: 80px 1fr;
  }

  @include md {
    // grid-template-columns: 1fr;
    flex-direction: column;
  }
}
