@import "../../assets/scss/variables";
@import "../../assets/scss/mixins";

.share-icon {
}

.share-icon-child {
  display: flex;
  align-items: center;
}

.share-close {
  display: none;
}

.share-open {
  position: fixed;
  display: flex;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  flex-direction: column;
  height: 300px;
  max-height: 30%;
  width: 500px;
  max-width: 90%;
  z-index: 4;
  word-wrap: break-word;
  background: white;
  border-radius: 15px;
  border: 1px solid #e7e7e7;
  text-align: center;
  justify-content: space-between;
  box-shadow: 0 0 20px 0px black;

  > div {
    font-weight: 900;
    height: 33.333333%;
    display: flex;
    flex-direction: column;
    justify-content: center;
  }
}

.share-close-pop {
  color: #ed4956;
  border-top: 1px solid #a8a8a8;
  width: 100%;
  border-bottom-right-radius: 15px;
  border-bottom-left-radius: 15px;
}

.share-link {
  border-bottom: 1px solid #a8a8a8;
  border-top-left-radius: 15px;
  border-top-right-radius: 15px;
  font-weight: 500 !important;
  padding: 0px 10px;
}
