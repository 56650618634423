// .heart {
//   font-size: 24px;
//   cursor: pointer;
//   user-select: none;
//   display: inline-block;
// }

// .heart:before {
//   // content: "\2764";
//   color: #000;
// }

// .heart.liked:before {
//   color: red;
//   animation: pulse 1s ease forwards;
// }

.liked-pulse {
  > svg {
    fill: #a226fc;
  }
  animation: pulse 1s ease forwards;
}

.saved-pulse {
  > svg {
    color: #a226fc;
  }
  animation: pulse 1s ease forwards;
}

@keyframes pulse {
  0% {
    transform: scale(1);
  }
  25% {
    transform: scale(1.25);
  }
  50% {
    transform: scale(1.1);
  }
  75% {
    transform: scale(1.25);
  }
  100% {
    transform: scale(1);
  }
}

.fill-delete {
  display: block;
  background: linear-gradient(to right, #9fa0a1 50%, transparent 0);
  background-size: 200% 100%;
  background-position: right;
  border-top-left-radius: 12px;
  animation: makeItfadeIn 3s 1s infinite;
}

@keyframes makeItfadeIn {
  100% {
    background-position: left;
  }
}

.loader {
  font-size: 10px;
  border-top: 4px solid rgba(255, 255, 255, 0.2);
  border-right: 4px solid rgba(255, 255, 255, 0.2);
  border-bottom: 4px solid rgba(255, 255, 255, 0.2);
  border-left: 4px solid #ffffff;
  transform: translateZ(0);
  animation: loaderSpin 1.1s infinite linear;
  margin-right: 10px;
}

.loader,
.loader:after {
  border-radius: 50%;
  width: 20px;
  height: 20px;
}

@keyframes loaderSpin {
  0% {
    transform: rotate(0deg);
  }
  100% {
    transform: rotate(360deg);
  }
}
