@import "../../assets/scss/variables";
@import "../../assets/scss/mixins";

.friends-profile {
  //   margin: 30px auto 0px auto;
  width: 100%;
  height: 100vh;

  @include lm {
    // margin: 5px auto;
  }

  @include md {
    // height: 95vh;
    margin: 0px auto;
    height: 95vh;
  }

  .friends-profile-container {
    // padding-bottom: 44px;
    max-width: 955px;
    display: flex;
    flex-direction: column;
    margin: auto;
    height: 100%;

    @include md {
      // width: 100vw;
    }

    .friends-letters {
      display: flex;
      justify-content: center;
      padding: 5px 0px;

      > p {
        font-weight: bold;
        font-size: 16px;
        font-family: -apple-system, BlinkMacSystemFont, "Segoe UI", Roboto,
          Helvetica, Arial, sans-serif;
        // text-shadow: 0 0 2px #3c69fe, 0 0 4px #3c69fe, 0 0 6px #a127fc, 0 0 8px #a127fc;
        background-image: linear-gradient(90deg, #3c69fe, #a127fc);
        -webkit-background-clip: text;
        -webkit-text-fill-color: transparent;
      }
    }

    .friends-share-section {
      text-align: center;
      //   margin-top: 30px;
      // max-height: calc(100vh - 320px);
      //   height: 100vh;
      overflow-y: auto;
      overflow-x: hidden;
      padding-right: 5px;
      padding-left: 20px;
      //   height: 60%;

      @include lg {
        // margin-top: 5px;
        padding-left: 0px;
        padding-right: 0px;
        // height: calc(82%);
      }

      &::-webkit-scrollbar {
        width: 5px;
      }

      &::-webkit-scrollbar-track {
        border-radius: 10px;
      }

      &::-webkit-scrollbar-thumb {
        background: rgb(219, 219, 219);
        border-radius: 10px;
      }

      .friends-header-section {
        display: flex;
        flex-direction: column;
        border-bottom: 1px solid #efefef;
        //   padding-bottom: 20px;
        align-items: center;
        //   gap: 50px;
        //   height: 30%;

        .friends-profile-info {
          width: 100%;
          display: flex;
          flex-direction: column;
          align-content: space-around;

          .friends-top-row {
            width: 100%;
            display: flex;
            flex-direction: row;
            justify-content: space-between;
            align-items: center;
            //   border: 1px solid red;

            .friends-profile-pic-section {
              // height: 150px;
              width: 30%;
              text-align: center;

              > img {
                border-radius: 100%;
                height: 120px;
                width: 120px;

                @include sm {
                  height: 100px;
                  width: 100px;
                }
              }
            }

            .friends-likes-and-stuff {
              width: 70%;
              display: flex;
              flex-direction: row;
              justify-content: space-evenly;

              .friends-lff {
                display: flex;
                flex-direction: column;
                align-items: center;
              }
            }

            @include sm {
              gap: 5px;
            }

            > div {
              .friends-count {
                font-weight: 600;
                font-size: 16px;
                font-family: -apple-system, BlinkMacSystemFont, "Segoe UI",
                  Roboto, Helvetica, Arial, sans-serif;
              }

              .friends-text {
                font-size: 16px;
                font-family: -apple-system, BlinkMacSystemFont, "Segoe UI",
                  Roboto, Helvetica, Arial, sans-serif;
                margin-left: 3px;

                @include sm {
                  font-size: 14px;
                }
              }
            }
          }

          .friends-middle-row {
            display: flex;
            justify-content: center;
            //   border: 1px solid blue;
            padding: 10px 0px;

            > div {
              display: flex;
              justify-content: end;

              > span {
                font-weight: 600;
                font-family: -apple-system, BlinkMacSystemFont, "Segoe UI",
                  Roboto, Helvetica, Arial, sans-serif;
                font-size: 14px;
                white-space: pre-line;
                text-align: left;
              }
            }
          }

          .friends-bottom-row {
            width: 100%;
            display: flex;
            justify-content: space-evenly;
            justify-items: start;
            align-items: center;
            //   border: 1px solid orange;
            padding: 10px 0px;
            //   gap: 85px;

            .follow-button {
              padding: 7px 20px;
              display: flex;
              align-items: center;
              // background-color: rgb(0, 149, 246);
              background-image: linear-gradient(90deg, #3c69fe, #a127fc);
              border-radius: 8px;
              justify-content: center;
              cursor: pointer;

              > span {
                color: white;
                font-family: -apple-system, BlinkMacSystemFont, "Segoe UI",
                  Roboto, Helvetica, Arial, sans-serif;
                font-weight: 600;
                font-size: 14px;
              }

              &:hover {
                background-color: rgb(17, 19, 23);
              }
            }

            .unfollow-button {
              padding: 7px 20px;
              display: flex;
              align-items: center;
              background-color: rgb(239, 239, 239);
              border-radius: 8px;
              justify-content: center;
              cursor: pointer;

              > span {
                color: black;
                font-family: -apple-system, BlinkMacSystemFont, "Segoe UI",
                  Roboto, Helvetica, Arial, sans-serif;
                font-weight: 600;
                font-size: 14px;
              }

              &:hover {
                background-color: rgb(219, 219, 219);
              }
            }

            .settings {
              cursor: pointer;
              display: flex;
              align-items: center;
            }
          }
        }
      }

      .friends-publications_body {
        // justify-items: center;
        display: flex;
        flex-direction: row;
        flex-wrap: wrap;
        // height: 100%;
        // justify-content: space-between;

        .friends-publication_div {
          flex: 0 0 33.333333%;
          // width: 300px;
          // height: 300px;

          // @include lg {
          //   height: 250px;
          //   width: 250px;
          // }

          // @include md {
          //   height: 180px;
          //   width: 180px;
          // }

          // @include sm {
          //   width: 115px;
          //   height: 115px;
          // }

          // @include xl {
          //   width: 250px;
          //   height: 250px;
          // }

          .friends-image-container {
            // max-width: 300px;
            // height: 300px;
            // width: 100%;

            > img {
              width: 100%;
              height: 100%;
              aspect-ratio: 1 / 1;
              object-fit: cover;
              border-radius: 20px;
            }

            // @include lg {
            //   height: 250px;
            //   width: 250px;
            // }

            // @include md {
            //   height: 180px;
            //   width: 180px;
            // }

            // @include sm {
            //   width: 115px;
            //   height: 115px;
            // }

            // @include xl {
            //   width: 250px;
            //   height: 250px;
            // }
          }

          // .friends-hovered_div {
          //   // position: absolute;
          //   display: none;
          //   top: 0;
          //   left: 0;
          //   width: 100%;
          //   height: 100%;
          //   color: white;

          //   > ul {
          //     list-style: none;
          //     align-items: center;
          //     justify-content: center;
          //     padding: 110px 15px;
          //     display: flex;

          //     > li {
          //       font-weight: 600;
          //       font-size: 18px;
          //     }
          //   }
          // }

          // &:hover {
          //   .friends-hovered_div {
          //     display: block;
          //     z-index: 2;
          //     background-color: #1f1d1d44;
          //   }
          // }
        }
      }
    }

    .save-section {
      text-align: center;
      margin-top: 60px;
      font-weight: 800;
      font-size: 30px;
      font-family: -apple-system, BlinkMacSystemFont, "Segoe UI", Roboto,
        Helvetica, Arial, sans-serif;

      > img {
        border-radius: 100%;
        height: 70px;
        width: 70px;
      }

      > h1 {
        font-family: -apple-system, BlinkMacSystemFont, "Segoe UI", Roboto,
          Helvetica, Arial, sans-serif;
        font-size: 30px;
        font-weight: 800;
        margin-bottom: 24px;
      }

      > p {
        font-weight: 400;
        font-size: 14px;
        font-family: -apple-system, BlinkMacSystemFont, "Segoe UI", Roboto,
          Helvetica, Arial, sans-serif;
        margin-bottom: 24px;
        max-width: 345px;
        margin: 0 auto;
      }
    }
  }
}
