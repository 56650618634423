@import "../../../assets/scss/variables";
@import "../../../assets/scss/mixins";

.modal {
  z-index: 9999;

  .close-modal {
    cursor: pointer;
  }

  .modal-content {
    margin: auto;
    .modal-block {
      background-color: white;
      border-radius: 12px;

      .modal-header {
        text-align: center;
        padding: 10px 0;
        border-bottom: 1px solid rgb(219, 219, 219);

        > h4 {
          font-size: 16px;
          font-weight: 600;
          font-family: -apple-system, BlinkMacSystemFont, "Segoe UI", Roboto,
            Helvetica, Arial, sans-serif;
        }
      }

      .text-section {
        padding: 16px;

        > textarea {
          width: 100%;
          min-height: 150px;
          outline: none;
          padding: 5px;
          border: 1px solid rgb(219, 219, 219);
        }
      }

      .upload-file {
        padding: 16px;
        width: 150px;
        height: 100px;

        > div {
          position: relative;

          > img {
            height: 100%;
            width: 100%;
          }

          > div {
            background-color: grey;
            position: absolute;
            top: -5px;
            right: -5px;
            height: 18px;
            width: 18px;
            border-radius: 50%;
            display: flex;
            align-items: center;
            justify-content: center;
            cursor: pointer;
            padding: 3px;
          }
        }
      }

      .send-add {
        padding: 0 16px;
        display: flex;
        justify-content: space-between;

        .send {
          background-color: rgb(0, 149, 246);
          cursor: pointer;
          font-weight: 600;
          font-family: -apple-system, BlinkMacSystemFont, "Segoe UI", Roboto,
            Helvetica, Arial, sans-serif;
          border-radius: 8px;
          border: none;
          outline: none;
          padding: 7px 20px;
          color: white;

          &:disabled {
            opacity: 0.3;
            cursor: default;
          }

          &:hover {
            background-color: rgb(24, 119, 242);
          }
        }

        .add {
          background-color: rgb(239, 239, 239);

          border-radius: 8px;
          border: none;
          outline: none;
          padding: 7px 20px;
          cursor: pointer;

          &:hover {
            background-color: rgb(219, 219, 219);
          }

          > label {
            font-weight: 600;
            font-family: -apple-system, BlinkMacSystemFont, "Segoe UI", Roboto,
              Helvetica, Arial, sans-serif;
            color: black;
            font-size: 14px;
            cursor: pointer;
          }
        }
      }

      .info {
        padding: 16px;

        > p {
          font-weight: 400;
          color: rgb(115, 115, 115);
          font-family: -apple-system, BlinkMacSystemFont, "Segoe UI", Roboto,
            Helvetica, Arial, sans-serif;
          word-break: break-word;
          max-width: 360px;
          font-size: 12px;
        }
      }
    }
  }
}
