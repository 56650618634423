@import "../../assets/scss/variables";
@import "../../assets/scss/mixins";

.timeline-page {
    width: 100%;
    max-height: 100dvh;
    padding: 0 40px;
    overflow: hidden;
    display: flex;

    .container {
        max-width: 620px !important;

        .spinner {
            padding: 15px 0px;
            width: 100px;
            left: 0;
            right: 0;
            margin: auto;
        }

        .timeline-section {
            max-height: 100dvh;
            max-width: 468px;
            overflow-y: auto;
            overflow-x: hidden;
            padding-top: 80px;
            padding-bottom: 80px;
            display: grid;
            justify-content: center;
            grid-gap: 24px;

            &::-webkit-scrollbar {
                display: none;
            }

            @include sm {
                padding-top: 20px;
                padding-bottom: 20px;
            }

        }

    }

    .user-section {
        .row {
            display: flex;
            justify-content: space-between;
            margin-right: 200px;
            margin-top: 50px;
            width: 100%;
            align-items: center;

            .image-box {
                display: flex;

                >div {
                    width: 44px;
                    height: 44px;

                    >img {
                        height: 100%;
                        width: 100%;
                    }
                }

                >p {
                    font-size: 14px;
                    font-weight: 600;
                    font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', Roboto, Helvetica, Arial, sans-serif;
                    margin-left: 12px;
                }
            }
        }

        .switch {
            >span {
                font-size: 12px;
                font-weight: 600;
                font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', Roboto, Helvetica, Arial, sans-serif;
                color: #0095f6;
                cursor: pointer;

                &:hover {
                    color: rgb(0, 55, 107);
                }
            }
        }

        .recommendations-title {
            margin-top: 24px;

            >h1 {
                font-size: 14px;
                color: #737373;
                font-weight: 600;
            }

            .recomendations {
                padding: 0px 10px;
                display: flex;
                flex-direction: column;
                align-items: center;

                .board_recomendations {
                    display: flex;
                    align-items: center;
                    width: 100%;
                    padding: 10px 5px;
                    gap: 10px;

                    .avatar_section {
                        >div {
                            >img {
                                width: 44px;
                                height: 44px;
                                border-radius: 50%;
                            }
                        }
                    }

                    .info_section {
                        display: flex;
                        flex-direction: column;
                        width: 100%;
                        padding-bottom: 5px;
                        justify-content: space-around;

                        >p {
                            cursor: pointer;
                            white-space: wrap;
                            font-weight: 600;
                            font-size: 14px;
                            font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', Roboto, Helvetica, Arial, sans-serif;

                        }

                        >span {

                            font-size: 12px;
                            font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', Roboto, Helvetica, Arial, sans-serif;
                            color: #737373;

                        }
                    }

                }

                .btn-subscribe {
                    cursor: pointer;
                    background-color: rgb(0, 149, 246);
                    padding: 8px 14px;
                    font-size: 14px;
                    color: white;
                    border-radius: 5px;
                    outline: none;
                    border: none;
                    font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', Roboto, Helvetica, Arial, sans-serif;
                    font-weight: 600;

                    &:hover {
                        background-color: rgb(24, 119, 242);
                    }
                }


            }

            >span {
                font-size: 12px;
                color: #c7c7c7;
                font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', Roboto, Helvetica, Arial, sans-serif;
                font-weight: 400;
            }
        }
    }

    @include md {
        grid-row: 1;
    }

    @include sm {
        padding: 0 15px;
    }
}