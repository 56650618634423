@import "../../assets/scss/variables";
@import "../../assets/scss/mixins";

.action-page {
    display: flex;
    justify-content: center;
    align-items: center;

    .action-page-container {
        border: 1px solid rgb(211, 211, 211);
        display: grid;
        grid-template-columns: 1fr 3fr;
        max-width: 935px;
        height: 800px;

        .left-section {
            border-right: 1px solid rgb(211, 211, 211);

            .title {
                border-bottom: 1px solid rgb(211, 211, 211);
                padding: 0 16px;
                height: 53px;
                display: flex;
                align-items: center;

                >h4 {
                    font-weight: 700;
                    font-size: 16px;
                    font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', Roboto, Helvetica, Arial, sans-serif;
                }
            }

            .sidebar-section {
                >div {
                    display: flex;
                    padding: 16px;
                    gap: 30px;
                    cursor: pointer;

                    .actions-icons {
                        height: 40px !important;
                        width: 40px !important;
                    }

                    >div {
                        >p {
                            font-size: 14px;
                            color: black;
                            font-weight: 600;
                            font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', Roboto, Helvetica, Arial, sans-serif;
                            margin-bottom: 6px;
                        }

                        >span {
                            font-size: 12px;
                            font-weight: 400;
                            color: #737373;
                            font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', Roboto, Helvetica, Arial, sans-serif;
                        }
                    }

                    &:hover {
                        border-left: 1px solid black;
                        padding-left: 15px;
                        background-color: rgb(250, 250, 250);
                    }
                }

                .active {
                    border-left: 1px solid black;
                    padding-left: 15px;
                    background-color: rgb(250, 250, 250);
                }
            }
        }

        .right-section {
            width: 100%;

            .tabs-section {

                .tabs {
                    display: flex;
                    justify-content: space-around;
                    align-items: center;
                    padding: 0 16px;
                    border-bottom: 1px solid rgb(211, 211, 211);
                    height: 53px;

                    >div {
                        display: flex;
                        justify-content: center;
                        align-items: center;
                        height: 100%;
                        cursor: pointer;

                        >svg {
                            height: 12px;
                            width: 12px;
                            color: rgb(115, 115, 115);
                        }

                        >p {
                            font-size: 12px;
                            color: rgb(115, 115, 115);
                            font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', Roboto, Helvetica, Arial, sans-serif;
                            font-weight: 600;
                            margin-left: 6px;
                        }
                    }

                    .active {
                        border-bottom: 1px solid black;

                        >svg {
                            color: black;
                        }

                        >p {
                            color: black;
                        }
                    }
                }

                .content {
                    .likes-content {
                        display: flex;
                        justify-content: space-between;
                        align-items: center;
                        padding: 16px 24px 0 24px;

                        .sorting {
                            display: flex;
                            align-items: center;

                            >h4 {
                                margin-right: 16px;
                                font-weight: 700;
                                font-size: 16px;
                            }

                            >button {
                                pointer-events: auto;
                                cursor: pointer;
                                background: rgba(0, 0, 0, 0);
                                border: 1px solid rgb(219, 219, 219);
                                border-radius: 8px;
                                overflow: hidden;
                                align-items: center;
                                justify-content: center;
                                padding: 5px 16px;
                                color: rgb(38, 38, 38);
                                font-weight: 700;
                                display: inline;
                                font-size: 14px;
                            }
                        }

                        >div {
                            span {
                                color: rgb(0, 149, 246);
                                cursor: pointer;
                            }
                        }
                    }

                    .comments-content {
                        text-align: center;

                        >img {
                            height: 96px;
                            width: 96px;
                            margin-top: 32px;
                        }

                        >h1 {
                            font-weight: 700;
                            font-size: 24px;
                            line-height: 1.3;
                            color: rgb(38, 38, 38);
                            font-family: system-ui, -apple-system, BlinkMacSystemFont, "Segoe UI", Roboto, Helvetica, Arial, sans-serif;
                            margin-top: 16px;
                            margin-bottom: 14px;
                        }

                        >span {
                            color: rgb(142, 142, 142);
                            font-size: 14px;
                            font-family: system-ui, -apple-system, BlinkMacSystemFont, "Segoe UI", Roboto, Helvetica, Arial, sans-serif;
                        }
                    }
                }
            }
        }
    }
}