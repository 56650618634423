@import "../../../assets/scss/variables";
@import "../../../assets/scss/mixins";

.modal {
  position: fixed;
  width: 100vw;
  height: 100vh;
  z-index: 2;
  top: 0;
  left: 0;
  background: rgba(0, 0, 0, 0.4);
  padding-top: 10px;

  .close-modal {
    position: absolute;
    right: 8px;
    top: 8px;

    @include sm {
      filter: invert(1);
      right: 4px;
      top: 4px;
    }
  }

  .modal-content {
    .product-details-modal {
      background: #fff;
      border-radius: 5px;
      width: calc(100% - 60px);
      height: calc(100vh - 10px);
      display: flex;
      margin: 0px auto;

      @include sm {
        width: calc(100% - 10px);
        height: calc(82vh);
      }

      @include md {
        display: block;
        // display: flex;
        // flex-direction: column;
        overflow: auto;
      }

      .product-image {
        border-right: 1px solid rgb(211, 211, 211);
        width: 100%;
        height: 100%;
        margin: auto;
        display: flex;
        justify-content: center;
        align-items: center;

        > img {
          object-fit: contain;
          max-width: 100%;
          max-height: 100%;
          border-radius: 20px;

          @include md {
            object-fit: contain;
          }
        }

        @include md {
          height: 60%;
        }
      }

      .product-comments-section {
        width: 40%;
        height: 100%;

        @include md {
          width: 100%;
          display: flex;
          flex-direction: column;
          height: 60%;
        }

        .comments-footer {
          width: 100%;
          border-top: 1px solid rgb(211, 211, 211);
          position: static;
          bottom: 0;
          height: 15%;

          @include md {
            padding-top: 0px;
            height: 20%;
            position: relative;
            bottom: 0;
          }

          .icons-section {
            display: flex;
            margin: auto;
            align-items: center;
            padding-left: 16px;
            padding-right: 16px;
            height: 30%;

            > div {
              display: flex;
              margin: auto;
              justify-content: space-between;
              align-items: center;
            }
          }

          .like {
            cursor: pointer;

            > span {
              font-weight: 600;
              color: rgb(0, 149, 246);
            }

            &:hover {
              opacity: 0.7;
            }
          }

          .add-comment-left {
            border-top: 1px solid rgb(211, 211, 211);
            display: flex;
            justify-content: space-between;
            align-content: stretch;
            height: 70%;
            padding: 2px 5px 2px 5px;

            > div {
              display: flex;
              align-items: center;
              width: 70%;

              > textarea {
                border: none;
                outline: none;
                width: 100%;
                height: 100%;
              }
            }

            .circle-button {
              display: flex;
              justify-content: flex-end;
              width: 15%;

              > div {
                height: 8px;
                width: 8px;
                background-color: rgb(1, 131, 116);
                border-radius: 50%;
              }

              > button {
                border: none;
                outline: none;
                color: #0095f6;
                background-color: unset;
                cursor: pointer;
                font-weight: 700;

                &:hover {
                  opacity: 0.7;
                }
              }
            }

            .emoji-open-icon {
              position: relative;
              cursor: pointer;
              width: 15%;

              .emoji-section {
                position: absolute;
                bottom: 30px;
              }
            }
          }
        }

        .comments-body {
          overflow: auto;
          height: 85%;
          width: 100%;

          @include md {
            height: 80%;
          }

          &::-webkit-scrollbar {
            width: 5px;
          }

          &::-webkit-scrollbar-track {
            border-radius: 10px;
          }

          &::-webkit-scrollbar-thumb {
            background: rgb(219, 219, 219);
            border-radius: 10px;
          }

          .comments-header {
            padding: 2px 16px;
            border-bottom: 1px solid rgb(211, 211, 211);
            display: flex;
            align-items: center;
            justify-content: space-between;

            .comments-header-username {
              display: flex;
              justify-content: center;
              align-items: center;

              > img {
                width: 32px;
                height: 32px;
                border-radius: 50%;
              }

              .user-name {
                margin-left: 16px;
                font-size: 14px;
              }
            }

            .comments-header-icon {
              cursor: pointer;
            }
          }

          .comments-description-section {
            padding: 16px;
            max-width: 800px;
            width: 100%;

            > div {
              display: flex;
              justify-content: start;

              .username {
                font-family: "Noto Sans", sans-serif;
                font-size: 14px;
                font-weight: bold;
              }

              .description {
                font-weight: 400;
                font-size: 13px;
                color: #000000;
              }

              .title {
                font-weight: bold;
                font-size: 15px;
                color: #000000;
              }

              > div {
                margin-top: 8px;

                > p {
                  font-size: 12px;
                  color: #737373;
                }
              }

              > div {
                > button {
                  border: none;
                  background-color: white;
                  color: #0095f6;
                  padding: 0px 2px 0px 2px;
                  cursor: pointer;
                  font-family: "Noto Sans", sans-serif;
                  font-weight: 600;

                  &:hover {
                    opacity: 0.5;
                  }
                }
              }
            }
          }

          .user-comment {
            padding: 0 16px;

            > div {
              width: 100%;
              display: flex;
              justify-content: space-between;
              margin-top: 10px;

              > img {
                width: 32px;
                height: 32px;
                border-radius: 50%;
              }

              .username {
                font-family: "Noto Sans", sans-serif;
                font-size: 14px;
                font-weight: bold;
              }

              .comment {
                word-break: break-word;
                margin-left: 7px;
                font-weight: 400;
                font-size: 13px;
                color: #000000;
              }

              > div {
                > span {
                  font-size: 12px;
                  color: #737373;
                }
              }

              .comment-row {
                display: flex;
                justify-content: space-between;
                // grid-gap: 20px;
                max-width: 800px;
                // width: 100%;
              }
              .remove-comment {
                text-align: right;
              }
            }
          }
        }
      }
    }
  }
}
