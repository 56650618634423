@import "../../../assets/scss/variables";
@import "../../../assets/scss/mixins";

.modal {
  position: fixed;
  width: 100vw;
  height: 100vh;
  z-index: 2;
  top: 0;
  left: 0;
  background: rgba(0, 0, 0, 0.4);
  // display: flex;
  padding-top: 10px;
  // align-items: center;

  .close-modal {
    position: absolute;
    right: 8px;
    top: 8px;

    @include sm {
      filter: invert(1);
      right: 4px;
      top: 4px;
    }
  }

  .modal-content {
    .product-details-modal-self {
      background: #fff;
      border-radius: 5px;
      width: calc(100% - 60px);
      height: calc(100vh - 10px);
      display: flex;
      margin: 0px auto;

      @include sm {
        width: calc(100% - 10px);
        height: calc(82vh);
      }

      @include md {
        display: block;
        overflow: auto;
      }

      .product-image {
        border-right: 1px solid rgb(211, 211, 211);
        width: 100%;
        height: 100%;
        margin: auto;
        display: flex;
        justify-content: center;
        align-items: center;

        > img {
          object-fit: contain;
          max-width: 100%;
          max-height: 100%;
          border-radius: 20px;

          @include md {
            object-fit: contain;
          }
        }

        @include md {
          height: 60%;
        }
      }

      .product-comments-section {
        width: 40%;
        height: 100%;

        @include md {
          width: 100%;
          display: flex;
          flex-direction: column;
          height: 60%;
        }

        .comments-footer {
          width: 100%;
          border-top: 1px solid rgb(211, 211, 211);
          position: static;
          bottom: 0;
          height: 15%;

          @include md {
            padding-top: 0px;
            height: 20%;
            position: relative;
            bottom: 0;
          }

          .icons-section {
            display: flex;
            margin: auto;
            align-items: center;
            padding-left: 16px;
            padding-right: 16px;
            height: 30%;

            > div {
              display: flex;
              margin: auto;
              justify-content: space-between;
              align-items: center;
            }
          }

          .add-comment-left {
            border-top: 1px solid rgb(211, 211, 211);
            display: flex;
            justify-content: space-between;
            align-content: stretch;
            height: 70%;
            padding: 2px 5px 2px 5px;

            > div {
              display: flex;
              align-items: center;
              width: 70%;

              > textarea {
                border: none;
                outline: none;
                width: 100%;
                height: 100%;
              }
            }

            .circle-button {
              display: flex;
              justify-content: flex-end;
              width: 15%;

              > div {
                height: 8px;
                width: 8px;
                background-color: rgb(1, 131, 116);
                border-radius: 50%;
              }

              > button {
                border: none;
                outline: none;
                color: #0095f6;
                background-color: unset;
                cursor: pointer;
                font-weight: 700;

                &:hover {
                  opacity: 0.7;
                }
              }
            }

            .emoji-open-icon {
              position: relative;
              cursor: pointer;
              width: 15%;

              .emoji-section {
                position: absolute;
                bottom: 30px;
              }
            }
          }
        }

        .comments-body {
          overflow: auto;
          height: 85%;
          width: 100%;

          @include md {
            height: 80%;
          }

          &::-webkit-scrollbar {
            width: 5px;
          }

          &::-webkit-scrollbar-track {
            border-radius: 10px;
          }

          &::-webkit-scrollbar-thumb {
            background: rgb(219, 219, 219);
            border-radius: 10px;
          }

          .comments-header {
            padding: 2px 16px;
            border-bottom: 1px solid rgb(211, 211, 211);
            display: flex;
            align-items: center;
            justify-content: space-between;

            .comments-header-username {
              display: flex;
              justify-content: center;
              align-items: center;

              > img {
                width: 32px;
                height: 32px;
                border-radius: 50%;
              }

              .user-name {
                margin-left: 16px;
                font-size: 14px;
              }
            }

            .comments-header-icon {
              cursor: pointer;
            }
          }

          .comments-description-section {
            padding: 16px;
            max-width: 800px;
            width: 100%;

            > div {
              padding: 16px 0 16px 16px;

              .description {
                font-weight: 400;
                font-size: 13px;
                color: #000000;
                word-break: break-word;
                margin-top: 1px;
              }

              .title {
                font-weight: bold;
                font-size: 15px;
                color: #000000;
                margin-right: 5px;
              }

              > button {
                border: none;
                cursor: pointer;
                color: #737373;
                background-color: white;
                font-weight: 700;

                &:hover {
                  color: #0095f6;
                  transition: 0.5s;
                }
              }
            }
          }

          > div {
            .item-builder {
              padding-left: 16px;

              .hider {
                display: none;
              }

              .item-update-areas {
                display: flex;
                flex-direction: column;
                gap: 10px;

                // align-items: center;
                > div {
                  > p {
                    font-size: 14px;
                    color: black;
                    font-family: -apple-system, BlinkMacSystemFont, "Segoe UI",
                      Roboto, Helvetica, Arial, sans-serif;
                    font-weight: 500;
                    margin-bottom: 4px;
                  }

                  > input {
                    padding: 8px 10px;
                    width: 80%;
                    outline: none;
                    border-top: none;
                    border-right: none;
                    border-left: none;
                    border-bottom: 1px solid rgb(211, 211, 211);

                    &:focus {
                      border-bottom: 1px solid #0095f6;
                    }
                  }

                  > textarea {
                    font: 14px;
                    width: 80%;
                    outline: none;
                    border: 1px solid rgb(211, 211, 211);
                    border-radius: 3px;
                    min-height: 100px;
                    resize: none;
                    padding: 10px;

                    &:focus {
                      border: 1px solid #0095f6;
                    }

                    &::-webkit-scrollbar {
                      width: 5px;
                    }

                    &::-webkit-scrollbar-track {
                      border-radius: 10px;
                    }

                    &::-webkit-scrollbar-thumb {
                      background: rgb(219, 219, 219);
                      border-radius: 10px;
                    }
                  }
                }

                .button_section {
                  width: 80%;
                  display: flex;
                  justify-content: end;

                  > button {
                    width: 50%;
                    background-color: #0095f6;
                    color: white;
                    border: none;
                    border-radius: 5px;
                    padding: 5px 0;
                    cursor: pointer;

                    &:hover {
                      opacity: 0.7;
                      transition: 0.5s;
                    }
                  }
                }
              }

              .show-item-update {
                margin-top: 10px;

                > button {
                  border: none;
                  background-color: white;
                  color: #0095f6;
                  cursor: pointer;
                  font-weight: 700;

                  &:hover {
                    opacity: 0.5;
                    transition: 0.5s;
                  }
                }
              }
            }
          }

          .user-comment {
            padding: 0 16px;

            > div {
              width: 100%;
              display: flex;
              justify-content: space-between;
              margin-top: 10px;
              // border: 1px solid red;

              .username {
                font-family: "Noto Sans", sans-serif;
                font-size: 14px;
                font-weight: bold;
              }

              .comment {
                word-break: break-word;
                margin-left: 7px;
                font-weight: 400;
                font-size: 13px;
                color: #000000;
              }

              > div {
                > span {
                  font-size: 12px;
                  color: #737373;
                  word-break: break-word;
                }
              }

              .comment-row {
                display: flex;
                justify-content: space-between;
                // margin-right: 20px;
                max-width: 800px;
                // width: 92%;
                // border: 1px solid blue;
              }
              .remove-comment {
                // width: 8%;
                // border: 1px solid orange;
                text-align: right;
              }
            }
          }
        }
      }
    }
  }
}
