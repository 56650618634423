@import "../../assets/scss/variables";
@import "../../assets/scss/mixins";

.spinner {
  position: absolute;
  padding: 15px 0px;
  width: 100px;
  top: 0;
  left: 0;
  right: 0;
  margin: auto;
}

.discovery-page {
  margin: 0 auto;

  .view-product-discovery {
    margin-top: 50px;

    @include md {
      margin-top: 16px;
    }

    .discovery-card {
      width: 570px;

      .discovery-card-header {
        display: flex;
        justify-content: space-between;
        align-items: center;
        width: 100%;

        > div {
          display: flex;

          .discovery-card-header-avatar {
            width: 42px;
            height: 42px;
            border-radius: 50%;
            overflow: hidden;
            margin-right: 16px;

            > img {
              width: 100%;
              height: 100%;
              object-fit: cover;
            }
          }

          .user-info {
            display: flex;
            align-items: center;

            .user-name {
              font-size: 14px;
              cursor: pointer;
              font-weight: 600;
              font-family: -apple-system, BlinkMacSystemFont, "Segoe UI", Roboto, Helvetica, Arial, sans-serif;
            }

            .date {
              font-size: 14px;
              color: darkgray;
              margin-left: 8px;
            }
          }
        }

        .three-dots {
          cursor: pointer;

          &:hover {
            opacity: 0.7;
          }
        }

        @include sm {
          padding: 0 16px;
        }
      }

      &-body {
        margin-top: 16px;
        width: 100%;

        .discovery-product-image {
          width: 570px;
          height: 550px;
          overflow: hidden;
          object-fit: contain;

          > img {
            width: 100%;
            height: 100%;
            border-radius: 20px;
          }

          @include sm {
            // width: 100%;
            // height: unset;
            // max-height: 480px;
            // max-width: 470px;
            height: 300px;
            width: 300px;
            margin: 0 auto;
          }
        }

        .product-actions {
          margin-top: 16px;
          display: flex;
          justify-content: space-between;
          align-items: center;

          .left-side-action {
            display: flex;
            align-items: center;

            > div {
              display: flex;
              margin: 0px 10px;
            }
          }

          .save-post {
            cursor: pointer;

            &:hover {
              opacity: 0.5;
            }
          }

          @include sm {
            padding: 0 16px;
          }
        }

        .product-desc {
          margin-top: 16px;

          .desc {
            font-size: 14px;

            > b {
              margin-right: 8px;
              font-size: 14px;
              cursor: pointer;
              font-weight: 600;
              font-family: -apple-system, BlinkMacSystemFont, "Segoe UI", Roboto, Helvetica, Arial, sans-serif;

              &:hover {
                opacity: 0.5;
              }
            }
          }

          @include sm {
            padding: 0 16px;
          }
        }

        .product-comments {
          margin-top: 16px;

          .view-comments {
            > p {
              font-size: 14px;
              color: #737373;
            }
          }

          @include sm {
            padding: 0 16px;
          }
        }

        .add-comment {
          display: flex;
          padding: 8px 0 16px;
          border-bottom: 1px solid rgb(219, 219, 219);
          margin-top: 8px;

          > input {
            border: none;
            outline: none;
            width: 100%;
          }

          .add_comment_icon {
            color: rgb(0, 149, 246);
            font-size: 14px;
            font-weight: 600;
            cursor: pointer;
          }

          .emoji-icon {
            width: 17px;
            height: 17px;
            margin-left: 10px;
            cursor: pointer;
            position: relative;

            > svg {
              height: 100%;
              width: 100%;
            }

            .emoji-section {
              position: absolute;
              bottom: 340px;
              height: 30px;
              width: 50px;
              right: 200px;
              @include lg {
                right: 180px;
                bottom: 250px;
              }
              @include sm {
                right: 140px;
                bottom: 170px;
              }
            }
          }

          @include sm {
            padding: 8px 16px 16px;
          }
        }
      }

      @include sm {
        max-width: 570px;
        width: 100%;
        padding: 0 10px;
      }
    }

    .bottom-section {
      display: grid;
      grid-template-columns: auto auto;
      margin-top: 50px;

      .dislike {
        display: flex;
        justify-content: flex-start;
        align-items: center;
        cursor: pointer;

        > span {
          font-weight: 600;
          color: rgb(0, 149, 246);
        }

        &:hover {
          opacity: 0.7;
        }
      }

      .like {
        display: flex;
        justify-content: flex-end;
        align-items: center;
        cursor: pointer;

        > span {
          font-weight: 600;
          color: rgb(0, 149, 246);
        }

        &:hover {
          opacity: 0.7;
        }
      }

      @include md {
        margin-top: 24px;
      }

      @include sm {
        padding: 0 16px;
      }
    }
  }

  @include md {
    grid-row: 1;
  }
}
