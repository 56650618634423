@import "../../assets/scss/variables";
@import "../../assets/scss/mixins";

.general-page {
    display: grid;
    grid-template-columns: 1fr 3fr;
    height: 100vh;
    margin-left: -180px;
    position: relative;
    z-index: 999;

    .users-section {
        border-right: 1px solid rgb(219, 219, 219);
        background-color: white;
        border-left: 1px solid rgb(219, 219, 219);

        .header-section {
            padding: 36px 24px 0px 24px;
            display: flex;
            justify-content: space-between;
            align-items: center;

            >h4 {
                font-size: 20px;
                font-weight: 700;
                font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', Roboto, Helvetica, Arial, sans-serif;
                white-space: nowrap;
            }

            >svg {
                cursor: pointer;
            }
        }

        .middle-section {
            margin-top: 40px;
            display: flex;
            justify-content: space-between;
            align-items: center;
            padding: 0 24px;

            >p {
                font-size: 16px;
                font-weight: 700;
                font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', Roboto, Helvetica, Arial, sans-serif;
            }

            >span {
                font-size: 14px;
                font-weight: 600;
                font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', Roboto, Helvetica, Arial, sans-serif;
                color: #737373;
            }
        }

        .message-section {
            height: 85%;
            display: flex;
            align-items: center;
            justify-content: center;

            >span {
                font-size: 14px;
                font-weight: 400;
                font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', Roboto, Helvetica, Arial, sans-serif;
            }
        }

        .message-block {
            padding: 8px 24px;
            grid-gap: 12px;

            .image_block {
                >img {
                    height: 56px;
                    width: 56px;
                    border-radius: 50%;
                }
            }

            >h1 {
                font-size: 14px;
                font-weight: 400;
                font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', Roboto, Helvetica, Arial, sans-serif;
                margin-top: 10px;
            }
        }

        .message-row {
            display: flex;
            cursor: pointer;
        }
    }

    .my-messages {
        display: flex;
        justify-content: center;
        align-items: center;
        flex-direction: column;

        >p {
            font-size: 20px;
            font-weight: 400;
            font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', Roboto, Helvetica, Arial, sans-serif;
            margin-top: 20px;
        }

        >span {
            color: #737373;
            font-size: 14px;
            font-weight: 400;
            font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', Roboto, Helvetica, Arial, sans-serif;
            margin-top: 16px;
        }

        >button {
            margin-top: 20px;
            background-color: #0095F6;
            color: white;
            font-weight: 600;
            font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', Roboto, Helvetica, Arial, sans-serif;
            font-size: 14px;
            padding: 8px 16px;
            border: none;
            outline: none;
            border-radius: 8px;
            cursor: pointer;

            &:hover {
                background-color: rgb(24, 119, 242);
            }
        }
    }
}