@import "../../assets/scss/variables";
@import "../../assets/scss/mixins";

.timeline-card {
  .timeline-card-header {
    display: flex;
    justify-content: space-between;
    align-items: center;

    > div {
      display: flex;

      .timeline-card-header-avatar {
        width: 42px;
        height: 42px;
        border-radius: 50%;
        overflow: hidden;
        margin-right: 16px;

        > img {
          width: 100%;
          height: 100%;
          object-fit: cover;
          border-radius: 20px;
        }
      }

      .user-info {
        display: flex;
        align-items: center;

        .user-name {
          font-size: 14px;
          cursor: pointer;
          font-weight: 600;
          font-family: -apple-system, BlinkMacSystemFont, "Segoe UI", Roboto, Helvetica, Arial, sans-serif;
        }

        .date {
          font-size: 14px;
          color: darkgray;
          margin-left: 8px;
        }
      }
    }

    .three-dots {
      cursor: pointer;

      &:hover {
        opacity: 0.7;
      }
    }
  }

  &-body {
    margin-top: 16px;

    .product-image {
      max-width: 468px;
      max-height: 585px;
      overflow: hidden;

      > img {
        width: 100%;
        height: 100%;
        object-fit: cover;
      }
    }

    .product-actions {
      margin-top: 16px;
      display: flex;
      justify-content: space-between;
      align-items: center;

      .left-side-action {
        display: flex;
        align-items: center;

        // .like {
        //   cursor: pointer;

        //   > span {
        //     font-weight: 600;
        //     color: rgb(0, 149, 246);
        //   }

        //   &:hover {
        //     opacity: 0.7;
        //   }
        // }

        > div {
          display: flex;
          margin: 0px 10px;
          // justify-content: space-between;
          // align-items: center;
        }
      }

      .save-post {
        cursor: pointer;

        &:hover {
          opacity: 0.5;
        }
      }
    }

    .product-desc {
      margin-top: 16px;

      .desc {
        font-size: 14px;

        > b {
          margin-right: 8px;
          font-size: 14px;
          cursor: pointer;
          font-weight: 600;
          font-family: -apple-system, BlinkMacSystemFont, "Segoe UI", Roboto, Helvetica, Arial, sans-serif;

          &:hover {
            opacity: 0.5;
          }
        }
      }
    }

    .product-comments {
      margin-top: 16px;

      .view-comments {
        > p {
          font-size: 14px;
          color: #737373;
        }
      }
    }

    .add-comment {
      display: flex;
      padding: 8px 0 16px;
      border-bottom: 1px solid rgb(219, 219, 219);
      margin-top: 8px;

      > input {
        border: none;
        outline: none;
        width: 100%;
      }

      .add_comment_icon {
        color: rgb(0, 149, 246);
        font-size: 14px;
        font-weight: 600;
        cursor: pointer;
      }

      .emoji-icon {
        width: 17px;
        height: 17px;
        margin-left: 10px;
        cursor: pointer;
        position: relative;

        > svg {
          height: 100%;
          width: 100%;
        }

        .emoji-section {
          position: absolute;
          bottom: 340px;
          height: 30px;
          width: 50px;
          right: 320px;

          @include lg {
            right: 230px;
            bottom: 240px;
          }

          @include md {
            right: 200px;
            bottom: 220px;
          }

          @include sm {
            right: 145px;
            bottom: 170px;
          }
        }
      }
    }
  }
}
