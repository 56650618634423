@import "../../assets/scss/variables";
@import "../../assets/scss/mixins";

.search-page {
  margin: 0 auto;
  position: relative;
  width: 100%;
  display: flex;
  flex-direction: column;
  align-items: center;

  .search-section {
    width: 100%;
    max-width: 570px;
    margin-top: 30px;

    @include sm {
      padding: 0 10px;
    }

    > h1 {
      @include sm {
        font-size: 22px;
      }
    }

    .tab-section {
      display: grid;
      width: 100%;
      grid-template-columns: auto auto;
      margin-top: 20px;

      > button {
        height: 40px;
        border-top: none;
        border-bottom: 1px solid #737373;
        border-right: none;
        border-left: none;
        outline: none;
        background-color: white;
        cursor: pointer;
        font-size: 16px;
        font-weight: 600;
        color: #737373;

        @include sm {
          font-size: 14px;
        }
      }

      .active {
        color: rgb(0, 149, 246);
        border-bottom: 2px solid rgb(0, 149, 246);
      }
    }

    .input-section {
      margin-top: 40px;
      width: 100%;
      height: 40px;

      > input {
        width: 100%;
        height: 100%;
        border-bottom: 1px solid #737373;
        border-top: none;
        border-left: none;
        border-right: none;

        &:focus {
          outline: none;
          border-bottom: 2px solid rgb(0, 149, 246);
        }
      }
    }
  }

  .users-results-section {
    margin-top: 30px;
    position: relative;
    margin-bottom: 100px;
    max-height: 600px;
    overflow-y: auto;
    padding-right: 5px;
    max-width: 570px;
    width: 100%;

    @include sm {
      padding: 0 10px;
    }

    &::-webkit-scrollbar {
      width: 5px;
    }

    &::-webkit-scrollbar-track {
      border-radius: 10px;
    }

    &::-webkit-scrollbar-thumb {
      background: rgb(219, 219, 219);
      border-radius: 10px;
    }

    .spinner {
      position: absolute;
      padding: 15px 0px;
      width: 100px;
      top: 50px;
      left: 0;
      right: 0;
      margin: auto;
    }

    .history-users {
      display: flex;
      justify-content: space-between;
      align-items: center;
      width: 100%;
      margin-top: 10px;
      padding: 4px 5px;
      cursor: pointer;

      .avatar_section {
        height: 40px;
        width: 40px;
        border-radius: 50%;

        > img {
          height: 100%;
          width: 100%;
          border-radius: 50%;
        }
      }

      .info_section {
        > p {
          word-break: break-all;
          width: 100%;
          font-size: 14px;
          font-weight: 600;
          font-family: -apple-system, BlinkMacSystemFont, "Segoe UI", Roboto, Helvetica, Arial, sans-serif;
        }
      }

      &:hover {
        background-color: rgb(222, 222, 222);
        border-radius: 5px;
        transition: 0.6s;
      }
    }
  }

  .no-users-results {
    position: relative;
    display: grid;
    grid-template-columns: 1fr;

    .spinner {
      position: absolute;
      padding: 15px 0px;
      width: 100px;
      top: 50px;
      left: 0;
      right: 0;
      margin: auto;
    }

    .nothing-found {
      text-align: center;
      margin-top: 140px;

      > p {
        font-size: 16px;
        color: #737373;
        font-family: -apple-system, BlinkMacSystemFont, "Segoe UI", Roboto, Helvetica, Arial, sans-serif;
      }
    }
  }

  .products-results-section {
    margin-top: 30px;
    position: relative;
    display: grid;
    grid-template-columns: repeat(3, 170px);
    gap: 20px;
    max-height: 600px;
    overflow-y: auto;
    padding-right: 10px;
    padding-left: 10px;
    padding-top: 20px;
    padding-bottom: 20px;
    overflow-x: hidden;
    max-width: 570px;
    width: 100%;

    @include sm {
      grid-template-columns: repeat(2, 1fr);
    }

    &::-webkit-scrollbar {
      width: 5px;
    }

    &::-webkit-scrollbar-track {
      border-radius: 10px;
    }

    &::-webkit-scrollbar-thumb {
      background: rgb(219, 219, 219);
      border-radius: 10px;
    }

    .spinner {
      position: absolute;
      padding: 15px 0px;
      width: 100px;
      top: 50px;
      left: 0;
      right: 0;
      margin: auto;
    }

    .history-products {
      width: 100%;
      cursor: pointer;

      .product-image {
        width: 100%;
        height: 150px;

        > img {
          width: 100%;
          height: 100%;
          object-fit: contain;
          border-radius: 20px;
        }
      }

      .info-section {
        width: 100%;

        > p {
          word-break: break-all;
          width: 100%;
          font-size: 14px;
          font-weight: 400;
          margin-top: 10px;
          font-family: -apple-system, BlinkMacSystemFont, "Segoe UI", Roboto, Helvetica, Arial, sans-serif;
        }
      }

      &:hover {
        transform: scale(1.1);
        transition: 0.6s;
      }
    }
  }

  .no-products-results {
    position: relative;
    display: grid;
    grid-template-columns: 1fr;

    .spinner {
      position: absolute;
      padding: 15px 0px;
      width: 100px;
      top: 50px;
      left: 0;
      right: 0;
      margin: auto;
    }

    .nothing-found {
      text-align: center;
      margin-top: 140px;

      > p {
        font-size: 16px;
        color: #737373;
        font-family: -apple-system, BlinkMacSystemFont, "Segoe UI", Roboto, Helvetica, Arial, sans-serif;
      }
    }
  }

  .pagination {
    position: absolute;
    bottom: 40px;
    display: grid;
    width: 100%;
    grid-template-columns: auto auto auto;

    .page-number {
      display: flex;
      justify-content: center;
      font-size: 16px;
      font-weight: 600;
      margin: auto;
      color: rgb(0, 149, 246);
    }

    > div {
      display: flex;
      justify-content: center;
      align-items: center;
      gap: 5px;
      cursor: pointer;

      > span {
        font-size: 16px;
        font-weight: 600;
        color: #737373;
      }

      &:hover {
        > span {
          color: rgb(0, 149, 246);
        }

        > svg {
          > path {
            fill: rgb(0, 149, 246);
          }
        }
      }
    }

    .next {
      > svg {
        transform: rotate(90deg);
        height: 14px;
        width: 14px;

        > path {
          fill: #737373;
        }
      }
    }

    .prev {
      > svg {
        transform: rotate(-90deg);
        height: 14px;
        width: 14px;

        > path {
          fill: #737373;
        }
      }
    }
  }
}
