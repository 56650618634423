@import "../../assets/scss/variables";
@import "../../assets/scss/mixins";

.profile-page {
  //   margin: 30px auto 0px auto;
  // height: 100vh;
  width: 100%;
  height: 100vh;

  @include md {
    // height: 95vh;
    margin: 0px auto;
    height: 95vh;
  }

  @include lm {
  }

  .profile-page-container {
    // padding-bottom: 44px;
    max-width: 955px;
    display: flex;
    flex-direction: column;
    margin: auto;
    height: 100%;

    @include md {
      // width: 100vw;
    }

    .letters {
      display: flex;
      justify-content: center;
      padding: 5px 0px;

      > p {
        font-weight: bold;
        font-size: 16px;
        font-family: -apple-system, BlinkMacSystemFont, "Segoe UI", Roboto,
          Helvetica, Arial, sans-serif;
        // text-shadow: 0 0 2px #3c69fe, 0 0 4px #3c69fe, 0 0 6px #a127fc, 0 0 8px #a127fc;
        background-image: linear-gradient(90deg, #3c69fe, #a127fc);
        -webkit-background-clip: text;
        -webkit-text-fill-color: transparent;
      }
    }

    .share-section {
      text-align: center;
      //   margin-top: 30px;
      // max-height: calc(100vh - 320px);
      //   height: 100vh;
      overflow-y: auto;
      overflow-x: hidden;
      padding-right: 5px;
      padding-left: 20px;
      //   height: 60%;

      @include lg {
        // margin-top: 5px;
        padding-left: 0px;
        padding-right: 0px;
        // height: calc(82%);
      }

      &::-webkit-scrollbar {
        width: 5px;
      }

      &::-webkit-scrollbar-track {
        border-radius: 10px;
      }

      &::-webkit-scrollbar-thumb {
        background: rgb(219, 219, 219);
        border-radius: 10px;
      }

      .header-section {
        display: flex;
        flex-direction: column;
        border-bottom: 1px solid #efefef;
        //   padding-bottom: 20px;
        align-items: center;
        //   gap: 50px;
        //   height: 30%;

        .profile-info {
          width: 100%;
          display: flex;
          flex-direction: column;
          align-content: space-around;

          .top-row {
            width: 100%;
            display: flex;
            flex-direction: row;
            justify-content: space-between;
            align-items: center;
            //   border: 1px solid red;

            .profile-pic-section {
              // height: 150px;
              width: 30%;
              text-align: center;

              > img {
                border-radius: 100%;
                height: 120px;
                width: 120px;

                @include sm {
                  height: 100px;
                  width: 100px;
                }
              }
            }

            .likes-and-stuff {
              width: 70%;
              display: flex;
              flex-direction: row;
              justify-content: space-evenly;

              .lff {
                display: flex;
                flex-direction: column;
                align-items: center;
              }
            }

            @include sm {
              gap: 5px;
            }

            > div {
              .count {
                font-weight: 600;
                font-size: 16px;
                font-family: -apple-system, BlinkMacSystemFont, "Segoe UI",
                  Roboto, Helvetica, Arial, sans-serif;
              }

              .text {
                font-size: 16px;
                font-family: -apple-system, BlinkMacSystemFont, "Segoe UI",
                  Roboto, Helvetica, Arial, sans-serif;
                margin-left: 3px;

                @include sm {
                  font-size: 14px;
                }
              }
            }
          }

          .middle-row {
            display: flex;
            justify-content: center;
            //   border: 1px solid blue;
            padding: 10px 0px;

            > div {
              display: flex;
              justify-content: end;

              > span {
                font-weight: 600;
                font-family: -apple-system, BlinkMacSystemFont, "Segoe UI",
                  Roboto, Helvetica, Arial, sans-serif;
                font-size: 14px;
                white-space: pre-line;
                text-align: left;
              }
            }
          }

          .bottom-row {
            width: 100%;
            display: flex;
            justify-content: space-evenly;
            justify-items: start;
            align-items: center;
            //   border: 1px solid orange;
            padding: 10px 0px;
            //   gap: 85px;

            .edit-button {
              display: flex;
              justify-content: center;

              > button {
                background-color: #efefef;
                border: 1px solid #efefef;
                border-radius: 8px;
                cursor: pointer;
                padding: 5px 16px;
                font-size: 14px;
                font-weight: 600;
                font-family: -apple-system, BlinkMacSystemFont, "Segoe UI",
                  Roboto, Helvetica, Arial, sans-serif;

                &:hover {
                  background-color: rgb(219, 219, 219);
                }

                @include sm {
                  font-size: 12px;
                }
              }
            }

            .settings {
              cursor: pointer;
              display: flex;
              align-items: center;
            }
          }
        }
      }

      .publications_body {
        // justify-items: center;
        display: flex;
        flex-direction: row;
        flex-wrap: wrap;
        // height: 100%;
        // justify-content: space-between;

        .publication_div {
          // cursor: pointer;
          // width: 300px;
          // height: 300px;
          flex: 0 0 33.333333%;

          // @include lg {
          //   height: 250px;
          //   width: 250px;
          // }

          // @include md {
          //   height: 180px;
          //   width: 180px;
          // }

          // @include sm {
          //   width: 115px;
          //   height: 115px;
          // }

          // @include xl {
          //   width: 250px;
          //   height: 250px;
          // }

          .image-container {
            // max-width: 309px;
            // width: 100%;
            // height: 309px;

            > img {
              width: 100%;
              height: 100%;
              object-fit: cover;
              aspect-ratio: 1 / 1;
              border-radius: 20px;
            }

            // @include lg {
            //   height: 250px;
            //   width: 250px;
            // }

            // @include md {
            //   height: 180px;
            //   width: 180px;
            // }

            // @include sm {
            //   width: 115px;
            //   height: 115px;
            // }

            // @include xl {
            //   width: 250px;
            //   height: 250px;
            // }
          }

          .hovered_div {
            // position: absolute;
            display: none;
            top: 0;
            left: 0;
            width: 100%;
            height: 100%;
            color: white;

            > ul {
              list-style: none;
              align-items: center;
              justify-content: center;
              padding: 110px 15px;
              display: flex;

              > li {
                font-weight: 600;
                font-size: 18px;
              }
            }
          }

          &:hover {
            .hovered_div {
              display: block;
              z-index: 2;
              background-color: #1f1d1d44;
            }
          }
        }
      }
    }

    .scroll_btn {
      display: flex;
      justify-content: center;
      margin-top: 10px;

      > div {
        height: 30px;
        width: 30px;
        cursor: pointer;

        > svg {
          height: 100%;
          width: 100%;

          > path {
            fill: #0095f6;
            box-shadow: border-box;
          }
        }
      }

      .scroll_down {
        transform: rotate(180deg);
        cursor: pointer;
      }
    }
  }
}
